import React, { useEffect } from 'react';
import Style from './style';

const Drawer = (props) => {
  const { visible, onClose } = props;

  const resetBodyStyle = () => {
    document.body.style.overflow = 'auto';
    document.body.style.position = 'static';
  };

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed'; // 果然是因为加了fixed，就会自动回滚到顶部
    } else {
      resetBodyStyle();
    }
    return () => {
      resetBodyStyle();
    };
  }, [visible]);

  return (
    <Style visible={visible}>
      <div className='drawer'>
        <div className='drawer-content'>
          <div className='drawer-header'>
            <span
              className='drawer-header-close'
              onClick={() => {
                resetBodyStyle();
                onClose();
              }}
            >
              &times;
            </span>
          </div>
          <div className='drawer-body'>{props?.children}</div>
        </div>
      </div>
    </Style>
  );
};

export default Drawer;
