import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Drawer from '../../../components/drawer';
import FilterHeader from '../components/filter-header';
import CollectionFilterVertical from '../collection-filter-vertical';
import Style from './style';

const CollectionMobileFilter = (props) => {
  const { onFilterChange, onSortChange, sortList, settings, data } = props;
  const { layout } = settings;
  const [bodyRef, setBodyRef] = useState(null);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setBodyRef(document?.getElementById('root'));
    window.addEventListener('resize', () => {
      const width = document?.body?.clientWidth || document?.documentElement?.clientWidth;
      if (width > 750) {
        setVisible(false);
      }
    });
    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  return (
    <Style layout={layout}>
      <div className='collection-mobile-filter'>
        <FilterHeader
          sortSelectorClassName={'mobile-sort-selector'}
          sortList={sortList}
          onSortChange={onSortChange}
          onClickFilter={() => {
            setVisible(!visible);
          }}
        />
        {bodyRef &&
          ReactDOM.createPortal(
            <Drawer
              visible={visible}
              onClose={() => {
                setVisible(!visible);
              }}
            >
              <CollectionFilterVertical
                data={data}
                settings={settings}
                onSortChange={onSortChange}
                onFilterChange={onFilterChange}
              />
            </Drawer>,
            bodyRef,
          )}
      </div>
    </Style>
  );
};

export default CollectionMobileFilter;
