import styled, { css } from 'styled-components';

const getContainerWidthCSS = (props) => {
  const { containerWidth } = props;
  return css`
    width: ${containerWidth === 'full_screen' || containerWidth === 'margin_80' ? '100%' : `${containerWidth}px`};
  `;
};

const getLayoutCSS = (props) => {
  const { layout } = props;
  if (layout === 'vertical') {
    return css`
      display: flex;
      padding-top: 24px;
    `;
  }
};

export default styled.div`
  .collection-template {
    padding: ${(props) => (props?.containerWidth === 'full_screen' ? '0' : '0 80px')};
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    .collection-template-body {
      margin: 0 auto;
      box-sizing: border-box;
      max-width: 100%;
      margin-bottom: ${(props) => props.theme.getSpace([16, 80])};
      ${(props) => getContainerWidthCSS(props)};
      ${(props) => getLayoutCSS(props)};
      .collection-template-body-content {
        flex: 1;
        .collection-mobile-filter {
          margin-bottom: 24px;
        }
      }
    }
  }

  @media screen and (max-width: 750px) {
    .collection-template {
      padding: ${(props) => (props?.containerWidth === 'full_screen' ? '0' : '0 16px')};
      width: 100%;
      box-sizing: border-box;
    }
  }
`;
