import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { envInitialData } from '../../util';
import { ContextManager } from '../context/context-manager';

import CollectionFilter from './sections/collection-filter';
import CollectionMobileFilter from './sections/collection-filter/collection-mobile-filter';
import CollectionPager from './sections/collection-pager';
import { useCollectionTheme } from './hooks/use-collection-theme';
import { useSortList } from 'hook/goods/use-sort-list';
import { useSearchList } from 'hook/goods/use-search-list';
import DiscountModal from '../components/discount/discount-modal';
import { Background } from '../design/components/background';
import GoodsCollectionWithLayout from '../design/components/goods-collection-with-layout';
import { buriedPoint } from 'utils/log-buried-point';

import Style from './style';

const width = 600;

const imageUrlTreating = (url) => {
  const flag = url.indexOf('?') > -1;
  if (url.indexOf('intl-image.yzcdn.cn') > -1) {
    if (flag) {
      return `${url}&w=${width}`;
    }
    return `${url}?w=${width}`;
  }
  if (flag) {
    return `${url}&imageView2/2/w/${width}`;
  }
  return `${url}?imageView2/2/w/${width}`;
};

const GoodsList = (props) => {
  const intl = useIntl();
  const initData = envInitialData(props) || {};
  const { collectionId } = initData;
  const currentPage = collectionId ? `${collectionId}` : 'allGoodsPage';

  const { theme = {}, animationSelectorClass } = useContext(ContextManager);
  const { collectionTemplateSettings, filterSettings } = useCollectionTheme({ theme });
  const { sortList } = useSortList({ currentPage, initData });

  const {
    grid = 5,
    pic_padding: picPadding,
    background_style: backgroundStyle = 'null',
    background_color: backgroundColor = '#f8f8f8',
    background_image: backgroundImage,
  } = collectionTemplateSettings;
  const {
    currency,
    // language,
    loading,
    page,
    pageSize,
    totalCount,
    renderList,
    filterItem,
    isActiveCustomSort,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    onFilterChange,
  } = useSearchList({
    initData,
    currentPage,
  });

  const goodsList = useMemo(() => {
    const newList = renderList.map((r) => {
      const newMedia = r?.media?.map((m) => {
        const url = imageUrlTreating(m?.url || '');
        return {
          ...m,
          url,
        };
      });
      return {
        ...r,
        media: newMedia,
      };
    });
    return newList;
  }, [renderList]);

  useEffect(() => {
    // 保证显示四行
    onPageSizeChange(grid * 4);
  }, [grid]);

  useEffect(() => {
    if (renderList.length && !loading) {
      buriedPoint('goods_view', {
        goods_component_location: collectionId ? 'collectionPage' : 'allGoodsPage',
        collectionId,
        goods_component_location_name: collectionId ? '商品分组页面' : '全部商品页面',
        goodsList: renderList.map((item) => {
          // eslint-disable-next-line
          const { goodsId: goods_id, goodsType: goods_type = '', title } = item;
          return {
            goods_id,
            goods_type,
            title,
          };
        }),
      });
    }
  }, [renderList.length, loading]);

  const Bg = Background(backgroundStyle);

  return (
    <Bg backgroundColor={backgroundColor} backgroundImage={backgroundImage}>
      <Style
        containerWidth={collectionTemplateSettings?.container_width}
        layout={filterSettings?.layout}
        className={animationSelectorClass}
      >
        {/* 折扣的弹窗 */}
        <DiscountModal intl={intl} initData={initData} />
        <div className='collection-template'>
          <div className='collection-template-body'>
            {/* 过滤器 */}
            <CollectionFilter
              settings={{
                ...filterSettings,
                currency,
              }}
              filterItem={filterItem}
              sortList={sortList}
              onSortChange={onSortChange}
              onFilterChange={onFilterChange}
            />
            <div className='collection-template-body-content'>
              {/* 过滤器-002 纵向和移动端的时候展示 */}
              <CollectionMobileFilter
                data={filterItem}
                settings={{
                  ...filterSettings,
                  currency,
                }}
                sortList={sortList}
                onSortChange={onSortChange}
                onFilterChange={onFilterChange}
              />
              {/* 商品列表 */}
              <GoodsCollectionWithLayout
                goodsList={goodsList}
                initialData={initData}
                settings={{
                  ...collectionTemplateSettings,
                  box_width: collectionTemplateSettings.container_width,
                  show_shopping_car: collectionTemplateSettings.show_product_cart_sku,
                  show_shopping_buy: collectionTemplateSettings.show_product_buy_btn,
                  product_title_align: collectionTemplateSettings.align_text,
                  rows: Math.ceil(pageSize / grid),
                  mobile_display_type: { main_val: collectionTemplateSettings.mobile_display_type },
                }}
                customGap={picPadding}
                isActiveCustomSort={isActiveCustomSort}
              />
              {/* 分页器-- 自定义排序显示500个 不显示页码 */}
              {!isActiveCustomSort && (
                <CollectionPager
                  settings={collectionTemplateSettings}
                  total={totalCount}
                  current={page}
                  pageSize={pageSize}
                  onPageChange={(data) => {
                    onPageChange(data?.current);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </Style>
    </Bg>
  );
};

export default GoodsList;
