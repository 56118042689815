import styled, { css } from 'styled-components';

const getVisibleCSS = (props) => {
  const { visible } = props;
  if (visible) {
    return css`
      .drawer {
        right: 0 !important;
        &:before {
          display: block !important;
        }
      }
    `;
  }
};

export default styled.div`
  ${(props) => getVisibleCSS(props)}
  .drawer {
    position: fixed;
    top: 0;
    right: -320px;
    display: flex;
    justify-content: flex-end;
    z-index: 2000;
    transition: all 0.3s;
    &:before {
      content: '';
      display: none;
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100vh;
      z-index: 1998;
      background: rgba(26, 26, 27, 0.3);
    }
    .drawer-content {
      top: 0;
      z-index: 1999;
      height: 100vh;
      width: 320px;
      display: flex;
      flex-direction: column;
      background-color: #fff;
    }
    .drawer-header {
      height: 72px;
      padding: 28px;
      box-sizing: border-box;
      box-shadow: inset 0px -0.5px 0px #d1d5db;
      .drawer-header-close {
        font-size: 28px;
        cursor: pointer;
      }
    }
    .drawer-body {
      flex: 1;
      box-sizing: border-box;
      overflow: auto;
      padding: 24px;
    }
  }
`;
