import styled, { css } from 'styled-components';

const getLayoutCSS = (props) => {
  const { layout } = props;
  if (layout === 'vertical') {
    return css`
      display: block;
    `;
  } else {
    return css`
      @media screen and (max-width: 750px) {
        display: block;
        margin-top: 16px;
      }
    `;
  }
};

export default styled.div`
  width: 100%;
  display: none;
  ${(props) => getLayoutCSS(props)};
`;
